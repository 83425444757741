.image {
  width: auto;
  max-width: 720px;
}
.image .image__inner {
  display: inline-block;
  width: 100%;
}
.image .image__desc {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #25242a;
  text-align: left;
  margin-top: 5px;
}

.image.image_with_desc a {
  text-decoration: none;
  color: #e4591f;
}

.image.image_center {
  display: block;
  margin: 0 auto;
}
