.h1,
h1 {
  font-family: Prata, 'Times New Roman', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 62px;
  line-height: 84px;
}

.h2,
h2 {
  font-family: Prata, 'Times New Roman', serif;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: #25242a;
}

p,
.p {
  font-size: 18px;
  line-height: 28px;
  color: #25242a;
  margin-top: 0;
  margin-bottom: 30px;
}

p.p_strong,
.p.p_strong {
  font-weight: 600;
}

.link {
  text-decoration: none;
  color: #e4591f;
}
