.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}

.menu .menu__item {
  text-decoration: none;
  color: white;
  padding: 20px;
  cursor: pointer;
  position: relative;
}

.menu .menu__item:hover::after,
.menu .menu__item_active::after {
  content: '';
  width: 10px;
  height: 10px;
  background: #e4591f;
  display: block;
  border-radius: 10px;
  position: absolute;
  bottom: -23px;
  left: 50%;
  margin-left: -5px;
}

.menu .menu__text {
  border-bottom: 1px solid transparent;
  transition: all 0.2s;
  position: relative;
  z-index: 3;
}
