.dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 20px 25px !important;
}

.dropdown .dropdown__icon {
  display: block;
  width: 13px;
  height: 7px;
  background: url('./img/icon-menu-arrow-bottom.svg') no-repeat center center;
  margin-left: 10px;
  position: relative;
  z-index: 3;
}

.dropdown .dropdown__trigger {
  z-index: 3;
}

.dropdown:hover {
  z-index: 4;
}
.dropdown:hover .dropdown__trigger {
  color: #25242a;
  border-color: transparent !important;
}

.dropdown:hover .dropdown__content {
  opacity: 1;
  visibility: visible;
}

.dropdown .dropdown__content {
  background: white;
  position: absolute;
  top: -5px;
  left: 0;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  border-radius: 10px;
  padding: 65px 30px 20px;
  min-width: 190px;
  width: 100%;
  text-align: left;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.15);
}

.dropdown .dropdown__content.dropdown__content_scroll .custom-scroll {
  height: 180px;
}

.dropdown .dropdown__item {
  color: #25242a;
  text-decoration: none;
  display: block;
  width: 100%;
  padding: 10px 0;
}

.dropdown .dropdown__text {
  border-bottom: 1px solid transparent;
  transition: all 0.2s;
}

.dropdown .dropdown__item.dropdown__item_active .dropdown__text,
.dropdown .dropdown__item:hover .dropdown__text {
  color: #e4591f;
}
