.section_text-with-right-content {
  display: flex;
  justify-content: space-between;
}

.section_text-with-right-content .section__text {
  width: 576px;
}

.section_text-with-right-content .section__right-content {
  width: 216px;
}
