.section {
  position: relative;
}

.section.section_white {
  background: white;
}

.section.section_gray {
  background: #faf9fa;
}
