.section_bio-short {
  display: flex;
  padding-top: 97px;
  padding-bottom: 70px;
  z-index: 1;
  position: relative;
}

.section_bio-short .section__text {
  margin-left: 145px;
  width: 430px;
}

.section_bio-short .section__image {
  width: 500px;
  margin-left: 120px;
}

.section_bio-short .section__image img {
  width: 100%;
}

.section_bio-short .section__desc {
  font-size: 18px;
  line-height: 28px;
  color: #25242a;
  margin-top: 45px;
}

.section_bio-short .section__title {
  margin-top: 60px;
  margin-bottom: 0;
}

.section_bio-short .section__action {
  margin-top: 50px;
}
