/*.page_bio .p, p,*/
.page_bio * + .section,
.page_bio * + .image_with_desc {
  margin-top: 80px;
}

.page_bio a.image_with_desc {
  text-decoration: none;
}

.page_bio .image_with_desc + * {
  margin-top: 68px;
}

.page_bio .icon_stars {
  display: block;
  margin: 100px auto 60px;
}

.page_bio .page__title + .image {
  margin-top: 0;
}

.page_bio .page__return-book {
  margin-top: 40px;
}
