.feedback {
  width: 576px;
}

.feedback.feedback_center {
  margin: 50px auto;
}

.feedback .feedback__item {
  width: 100%;
}

.feedback .feedback__item + .feedback__item {
  margin-top: 35px;
}

.feedback .feedback__action {
  margin-top: 44px;
  margin-bottom: 100px;
}

.feedback_errors {
  padding: 0 0 0 1em;
  margin: 0 0 2em 0;
}
.feedback_errors li {
  margin: 0 0 1em 0;
  color: #e4591f;
}
