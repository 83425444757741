.button {
  display: inline-block;
  outline: none;
}

.button.button_uppercase .button__text {
  text-transform: uppercase;
}

.button_theme_light {
  border: 1px solid #eae9ec;
  text-decoration: none !important;
  padding: 15px 24px;
  transition: all 0.2s;
}

.button_theme_light:hover {
  background: #e4591f;
  cursor: pointer;
  border-color: #e4591f;
}

.button_theme_light:hover .button__text {
  color: white;
}

.button_theme_light .button__text {
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #25242a;
  padding: 0 10px;
  display: block;
  letter-spacing: 0.05em;
  transition: all 0.2s;
}

.button_theme_super-big {
  border: 1px solid #eae9ec;
  box-sizing: border-box;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button_theme_super-big .button__arrow {
  width: 74px;
  height: 7px;
  background: url('./img/index-arrow-orange-right.svg') no-repeat center center;
  margin-right: 50px;
  transition: all 0.2s;
}

.button_theme_super-big:hover .button__arrow {
  margin-right: 40px;
}

.button_theme_super-big .button__text {
  padding: 42px;
  font-family: Prata, 'Times New Roman', serif;
  font-size: 24px;
  line-height: 44px;
  color: #25242a;
}
