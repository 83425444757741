.page_direction .page__title {
  padding-top: 0;
}

.page_direction .image {
  margin-bottom: 100px;
}

.page_direction .section_news .section__title {
  padding-top: 70px;
}
