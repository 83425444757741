.container {
  position: relative;
  width: 1300px;
  margin: 0 auto;
}

.container_short {
  position: relative;
  width: 864px;
  margin: 0 auto;
}

.container_short p {
  position: relative;
}

.container_short p img {
  max-width: 100%;
}
