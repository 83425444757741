.page_news-detail .image_with_desc {
  margin-bottom: 68px;
}

.page_news-detail .page__title {
  padding-top: 0;
  padding-bottom: 30px;
}

.page_news-detail .page__date {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #9b999e;
  margin-bottom: 85px;
}
