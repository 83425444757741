.socials {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 2;
  position: relative;
}

.socials .socials__item {
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100%;
  margin: 0 12px;
}

.socials .socials__item_vk {
  background: url('./img/icon-vk.svg') no-repeat center center;
}

.socials .socials__item_fb {
  background: url('./img/icon-fb.svg') no-repeat center center;
}

.socials .socials__item_rss {
  background: url('./img/icon-insta.svg') no-repeat center center;
}

.socials .socials__item_youtube {
  background: url('./img/icon-youtube.svg') no-repeat center center;
}
