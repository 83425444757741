.page {
  width: 100%;
  min-width: 1340px;
}

.page .page__wrapper {
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page .content {
  margin-top: 96px;
}

.page .page__content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.page .page__footer {
  flex: 0 0 auto;
  position: relative;
}

.page .page__title {
  text-align: center;
  padding: 106px 0 90px;
  margin: 0;
}

.page .page__title + .tabs {
  margin-top: -42px;
}
