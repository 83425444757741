.section_people .section__content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}

.section_people .section__item + .section__item {
  margin-left: 140px;
}

.section_people .section__image {
  width: 144px;
  height: 144px;
  background-size: cover;
  border-radius: 50%;
}

.section_people .section__text {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #25242a;
  margin-top: 35px;
}

.section_people .section__position {
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  margin-top: 14px;
  color: #36343d;
}
