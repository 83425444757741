.section_contacts .section__content {
  text-align: center;
  padding-bottom: 125px;
}

.section_contacts .section__action {
  font-family: Prata, 'Times New Roman', serif;
  font-size: 42px;
  line-height: 57px;
  color: #25242a;
  text-decoration: none;
}

.section_contacts .section__action + .section__action {
  margin-left: 60px;
}

.section_contacts .section__action_address {
  margin-top: 50px;
}

.section_contacts .section__action_email {
  color: #e4591f;
}

.section_contacts .section__action:hover {
  text-decoration: underline;
}

.section_contacts .section__success-image {
  width: 200px;
  height: 200px;
  margin: 120px auto 56px;
  background-image: url("data:image/svg+xml,%3Csvg width='200' height='200' viewBox='0 0 200 200' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M51.2 60c-6.3 0-11.3 5-11.3 11.3v59.3c0 6.2 5 11.3 11.3 11.3h97.3c6.3 0 11.4-5 11.4-11.3V71.3c0-6.3-5.1-11.4-11.4-11.4H51.2zm0 4h97.3c1.4 0 2.7.3 3.8 1L102 106.4 48.2 64.5c1-.4 2-.6 3-.6zm-4.7 1.7l55 42.8.6.2c.2 0 .4 0 .6-.2l51.2-42.2c1.2 1.3 2 3 2 5v59.3c0 1.9-.8 3.6-2 4.9a1 1 0 00-.2-.5l-34.4-30.7a1 1 0 00-1.3 1.5l34.4 30.7.2.2a7.3 7.3 0 01-4 1.2H51.1c-4 0-7.3-3.3-7.3-7.3V71.3c0-2.3 1-4.3 2.6-5.6zm40.4 2.2a7 7 0 100 14 7 7 0 000-14zm0 2a5 5 0 110 10 5 5 0 010-10zm10 0a1 1 0 000 2h24a1 1 0 000-2h-24zm0 4a1 1 0 000 2h10a1 1 0 000-2h-10zm-14.4 30.2a1 1 0 00-.7.2L47.4 135a1 1 0 101.3 1.5l34.4-30.7a1 1 0 00-.6-1.7z' fill='%23E4591F'/%3E%3C/svg%3E");
}

.section_contacts .section__success-title {
  margin-bottom: 46px;
}

.section_contacts .section__success-text {
  max-width: 542px;
  margin: 0 auto;
}

.section_contacts .section__success-text span {
  color: #9b999e;
}
