.section_news {
  padding-bottom: 70px;
}

.section_news .section__filter {
  text-align: center;
}

.section_news .section__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1080px;
  margin: 0 auto;
}

.section_news .section__content::after {
  content: '';
  flex: auto;
}

.section_news .short-info {
  margin: 65px 36px 0;
}
