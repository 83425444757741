@keyframes vex-slide-from-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(400px);
    transform: translateY(400px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes vex-slide-from-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-400px);
    transform: translateX(-400px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes vex-slide-from-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(400px);
    transform: translateX(400px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.modal_theme_vex {
  display: inline-block;
  text-align: left;
  width: 864px;
  vertical-align: middle;
  background: white;
  padding: 0 40px;
  border-radius: 8px;
  position: relative;
  -webkit-animation: vex-slide-from-bottom 0.5s;
  animation: vex-slide-from-bottom 0.5s;
}
.vex_animate_left .modal_theme_vex {
  -webkit-animation: vex-slide-from-left 0.5s;
  animation: vex-slide-from-left 0.5s;
}
.vex_animate_right .modal_theme_vex {
  -webkit-animation: vex-slide-from-right 0.5s;
  animation: vex-slide-from-right 0.5s;
}
.modal_theme_vex .modal__spinner {
  text-align: center;
}
.modal_theme_vex .modal__title {
  margin-top: 0;
}
.modal_theme_vex .modal__content {
  font-size: 16px;
  line-height: 20px;
}
.modal_theme_vex .modal__close {
  position: absolute;
  top: 31px;
  right: 31px;
  cursor: pointer;
}
.modal_theme_vex .modal__close:before {
  display: none;
}
.modal_theme_vex.modal_fluid {
  width: 100%;
  max-width: 920px;
  padding: 16px;
}
.vex {
  padding: 16px;
  text-align: center;
}
.vex:after {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em; /* Adjusts for spacing */
}
.vex.vex-closing .modal_theme_vex {
  -webkit-animation: vex-flyout 0.3s forwards;
  animation: vex-flyout 0.3s forwards;
}
.vex-overlay {
  opacity: 1;
  transition: opacity 0.3s;
}

.vex-overlay.vex-closing {
  opacity: 0;
}
