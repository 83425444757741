.only-book {
  text-decoration: none;
  transition: all 0.6s;
  transform: scale(0.5);
  display: block;
  width: 288px;
  position: relative;
}

.only-book .only-book__image {
  display: block;
  margin: 0 auto;
  transition: all 0.6s;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
}

.only-book .only-book__year {
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #36343d;
  margin-top: 30px;
  transition: all 0.2s;
  opacity: 0;
  visibility: hidden;
}
.only-book .only-book__name {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #25242a;
  margin-top: 6px;
  transition: all 0.2s;
  opacity: 0;
  visibility: hidden;
}
