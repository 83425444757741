.section_century-100 {
  background: #faf9fa;
  padding: 120px 0;
}

.section_century-100 .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1146px;
}

.section_century-100 .section__text {
  width: 504px;
}

.section_century-100 .section__title {
  margin-bottom: 60px;
  margin-top: 0;
}

.section_century-100 .section__desc {
  font-size: 18px;
  line-height: 28px;
  color: #25242a;
}
