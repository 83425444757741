.short-info {
  width: 288px;
  display: block;
  text-decoration: none;
}

.short-info .short-info__image {
  width: 100%;
  height: 244px;
  position: relative;
  background-size: cover;
  background-position: center center;
}

.short-info .short-info__date {
  font-size: 14px;
  line-height: 24px;
  color: #36343d;
  margin-top: 20px;
}

.short-info .short-info__title {
  font-size: 18px;
  line-height: 28px;
  color: #25242a;
  margin-top: 12px;
}

.short-info .short-info__icon_play {
  width: 33px;
  height: 33px;
  display: block;
  background: url('./img/play.svg') no-repeat center center;
  background-size: contain;
  position: absolute;
  bottom: 17px;
  left: 17px;
}
