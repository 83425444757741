.tabs {
  text-align: center;
  margin-bottom: 44px;
}

.tabs .tabs__item {
  display: inline-block;
  text-decoration: none;
  padding: 7px 15px;
  border-radius: 24px;
  border: 1px solid transparent;
  transition: all 0.2s;
}

.tabs .tabs__item + .tabs__item {
  margin-left: 5px;
}

.tabs .tabs__item:hover,
.tabs .tabs__item_active {
  border: 1px solid #e4591f;
  box-sizing: border-box;
}

.tabs .tabs__text {
  font-size: 18px;
  line-height: 28px;
  color: #25242a;
}

.tabs .tabs__count {
  color: #e4591f;
  font-weight: bold;
  margin-left: 4px;
}
