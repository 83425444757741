.carousel_books {
  outline: none;
  height: 550px;
}

.carousel_books .flickity-slider {
  align-items: center;
  display: flex;
}

.carousel_books .is-selected .only-book {
  transform: scale(1);
}

.carousel_books .is-selected .only-book__name,
.carousel_books .is-selected .only-book__year {
  visibility: visible;
  opacity: 1;
}

.carousel_books .flickity-prev-next-button.previous {
  left: -180px;
}

.carousel_books .flickity-prev-next-button.next {
  right: -180px;
}
.carousel_books .flickity-viewport {
  min-height: 550px;
}
.carousel_books .flickity-prev-next-button {
  background: transparent;
  border-radius: 0;
  width: 50%;
  height: 100%;
}

.carousel_books .flickity-prev-next-button .flickity-button-icon {
  display: none;
}
