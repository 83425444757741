.section_statue {
  width: 100%;
  height: 752px;
  background: #36343d;
  display: flex;
}

.section_statue .container {
  padding-left: 100px;
}

.section_statue .section__wrapper {
  position: relative;
  z-index: 1;
}

.section_statue .section__title {
  margin-top: 130px;
  color: #ffffff;
}

.section_statue .section__desc {
  width: 504px;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  margin-top: 60px;
}

.section_statue .section__link {
  font-size: 18px;
  line-height: 28px;
  color: #e4591f;
  text-decoration: none;
  margin-top: 55px;
  display: inline-block;
  padding-right: 120px;
  background: url('../img/index-arrow-orange-right.svg') no-repeat center right;
  transition: all 0.2s;
}

.section_statue .section__link:hover {
  padding-right: 130px;
}

.section_statue .section__statue {
  width: 648px;
  height: 634px;
  background: url('../img/statue.png');
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.section_statue .section__figure {
  position: absolute;
  left: 240px;
  top: -96px;
  z-index: 0;
}

.section_statue .section__infinity {
  width: 100vw;
  height: 756px;
  background: rgba(37, 36, 42, 0.95);
}
