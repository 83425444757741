.input_search {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.input_search .button {
  padding: 11px 24px;
  height: 52px;
}

.input_search .input__icon {
  width: 36px;
  height: 52px;
  margin-right: -40px;
  z-index: 3;
  background: url('./img/icon-magnifier-dark.svg') no-repeat center center;
}

.input_search .input__control {
  border-right: 0;
  margin-top: 0;
  padding-left: 50px;
}
