.page_gallery-detail .page__title {
  padding-top: 0;
  padding-bottom: 30px;
}

/* Стили для страницы конкретных галерей (/video/category/:id /albums/category/:id)*/

.video-modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 180px;
  margin-top: -90px;
  margin-left: -150px;
  z-index: 110;
}

@media (min-width: 768px) {
  .video-modal {
    width: 450px;
    height: 300px;
    margin-top: -150px;
    margin-left: -225px;
  }
}

@media (min-width: 992px) {
  .video-modal {
    width: 600px;
    height: 400px;
    margin-top: -200px;
    margin-left: -300px;
  }
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.gallery-item-list {
  display: none;
}
