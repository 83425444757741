.input {
  outline: none;
}

.input .input__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input .input__help {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #9b999e;
}

.input .input__label {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #25242a;
}

.input .input__control {
  width: 100%;
  background: #ffffff;
  border: 1px solid #eae9ec;
  box-sizing: border-box;
  resize: none;
  margin-top: 8px;
  outline: none;
  padding: 16px 20px;
  font-style: normal;
  font-weight: 300;
}

.input .input__control_area {
  min-height: 284px;
}
