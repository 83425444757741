.gallery {
  margin-bottom: 150px;
}

.gallery .gallery__item + .gallery__item {
  margin-top: 20px;
}

.gallery .gallery__item:hover .gallery__arrow {
  left: 82px;
}

.gallery .gallery__item {
  position: relative;
  text-decoration: none;
  display: block;
  background: #36343d;
  border-radius: 10px;
  overflow: hidden;
}

.gallery .gallery__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 69px 72px;
  border-radius: 10px;
  overflow: hidden;
  height: 240px;
  background-size: cover;
}

.gallery .gallery__name {
  font-family: Prata, 'Times New Roman', serif;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: #ffffff;
}

.gallery .gallery__count {
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  color: #ffffff;
  padding-right: 40px;
  margin-top: 5px;
}

.gallery .gallery__count_movie {
  background: url('./img/movie.svg') no-repeat right center;
}
.gallery .gallery__count_picture {
  background: url('./img/picture.svg') no-repeat right center;
}
.gallery .gallery__count_folder {
  background: url('./img/folder.svg') no-repeat right center;
}

.gallery .gallery__arrow {
  transition: all 0.2s;
  position: absolute;
  bottom: 25px;
  left: 72px;
  width: 44px;
  height: 44px;
  background: url('./img/icon-arrow-orange-next.svg') no-repeat center center;
}
