.icon {
  display: inline-block;
}

.icon.icon_16x16 {
  width: 16px;
  height: 16px;
}

.icon.icon_24x24 {
  width: 24px;
  height: 24px;
}

.icon.icon_26x26 {
  width: 26px;
  height: 26px;
}

.icon.icon_file_pdf {
  background: url('./img/icon-file-pdf.svg') no-repeat center center;
}

.icon.icon_file_doc {
  background: url('./img/icon-file-doc.svg') no-repeat center center;
}

.icon.icon_modal-close {
  background: url('./img/modal-close.svg') no-repeat center center;
}

.icon.icon_footer-arrow {
  background: url('./img/icon-footer-arrow-top.svg') no-repeat center center;
}

.icon.icon_stars {
  width: 42px;
  height: 11px;
  background: url('./img/stars.svg') no-repeat center center;
}
