.section_quote {
  width: 720px;
  padding: 74px 0 78px 72px;
  position: relative;
}

.section_quote:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 360px;
  height: 100%;
  background: #faf9fa;
  z-index: 1;
}

.section_quote .section_quote__text {
  font-family: Prata, 'Times New Roman', serif;
  font-size: 24px;
  line-height: 44px;
  color: #25242a;
  position: relative;
  z-index: 2;
}

.section_quote.section_quote_center {
  margin-left: auto;
  margin-right: auto;
}
