.book {
  background: #faf9fa;
  border-radius: 10px;
  text-align: center;
  width: 288px;
  padding: 0 50px 45px;
  margin: 150px 36px 0;
}

.book .book__inner {
  max-width: 144px;
  height: 150px;
  position: relative;
  margin: 0 auto;
}

.book .book__inner img {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 100%;
  height: auto;
  box-shadow: 0 10px 40px rgba(37, 36, 42, 0.1);
  border-radius: 6px;
}

.book .book__year {
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
  color: #36343d;
  margin-top: 40px;
}

.book .book__name {
  font-size: 18px;
  line-height: 28px;
  color: #25242a;
  margin-top: 10px;
}
