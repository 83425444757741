.search .search__help span {
  color: #9b999e;
}
.search .search__help {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #25242a;
  margin-top: 50px;
}

.search .search__field {
  width: 720px;
  margin: 0 auto;
}

.search .search__results {
  margin-top: 80px;
}
