.link {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  text-decoration: none;
}

.link.link_orange {
  color: #e4591f;
}

.link.link_orange:hover {
  opacity: 0.8;
}

.link.link_back {
  display: block;
  width: 150px;
  margin: 95px auto 43px;
}
