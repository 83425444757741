* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
}
