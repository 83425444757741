.section_base.section_base_legacy .tabs {
  margin-bottom: 60px;
}

.section_base.section_base_legacy .section__content {
  margin-top: 55px;
  margin-bottom: 125px;
  position: relative;
}

.glider-prev {
  left: 150px;
}

.glider-next {
  right: 150px;
}
