.search-field {
  display: flex;
  padding: 0 20px;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  transition: all 0.2s;
  z-index: 1;
}

.search-field.search-field_open {
  background: white;
  z-index: 3;
}

.search-field.search-field_open .search-field__magnifier {
  background: url('./img/icon-magnifier-dark.svg') no-repeat center center;
}

.search-field.search-field_open .search-field__input {
  opacity: 1;
  visibility: visible;
}

.search-field.search-field_open .search-field__close {
  opacity: 1;
  visibility: visible;
}

.search-field .search-field__magnifier {
  cursor: pointer;
  display: block;
  width: 19px;
  height: 19px;
  background: url('./img/icon-magnifier.svg') no-repeat center center;
  transition: all 0.2s;
}

.search-field .search-field__input {
  border: none;
  color: #25242a;
  outline: none !important;
  padding: 15px 10px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  background: transparent;
  width: 150px;
}

.search-field .search-field__close {
  cursor: pointer;
  display: block;
  width: 14px;
  height: 14px;
  background: url('./img/icon-close.svg') no-repeat center center;
  background-size: contain;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
}
