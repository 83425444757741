.footer {
  font-size: 14px;
  line-height: 24px;
  color: #9b999e;
  background: #ffffff;
  z-index: 1;
  position: relative;
}

.footer .footer__wrapper {
  display: flex;
  height: 84px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eae9ec;
}

.footer a {
  text-decoration: none;
  color: #9b999e;
}

.footer .footer__action_top {
  display: flex;
  align-items: center;
  justify-items: center;
}

.footer .footer__action_top .icon {
  margin-right: 10px;
}

.footer .footer__text {
  border-bottom: 1px solid transparent;
  transition: all 0.2s;
}

.footer .footer__action:hover .footer__text {
  border-bottom: 1px solid #9b999e;
}
