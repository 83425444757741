.page_index .page__wrapper {
  background: #faf9fa;
}

.page_index .content {
  margin-top: 848px;
  background: #faf9fa;
}

.page_index .page__statue {
  position: fixed;
  top: 96px;
}

.page_index .page__hidden {
  display: none;
}
