.breadcrumbs {
  width: 1008px;
  display: flex;
  border-top: 1px solid #eae9ec;
  padding: 50px 0 120px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.breadcrumbs .breadcrumbs__items {
  display: flex;
}

.breadcrumbs .breadcrumbs__item {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eae9ec;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 14px;
  line-height: 28px;
  text-transform: uppercase;
  color: #25242a;
  transition: all 0.2s;
  margin: 0 9px;
}

.breadcrumbs .breadcrumbs__item_dots {
  border-color: transparent;
  pointer-events: none;
}

.breadcrumbs .breadcrumbs__item_active,
.breadcrumbs .breadcrumbs__item:hover {
  background: #25242a;
  color: #ffffff;
}

.breadcrumbs .breadcrumbs__arrows {
  border: 0;
  width: 45px;
  height: 45px;
}

.breadcrumbs .breadcrumbs__arrows_prev {
  background: url('./img/icon-arrow-orange-prev.svg') no-repeat center center;
}

.breadcrumbs .breadcrumbs__arrows_next {
  background: url('./img/icon-arrow-orange-next.svg') no-repeat center center;
}

.breadcrumbs .breadcrumbs__arrows_prev.breadcrumbs__arrows_disabled {
  pointer-events: none;
  background: url('./img/icon-arrow-gray-prev.svg') no-repeat center center;
}

.breadcrumbs .breadcrumbs__arrows_next.breadcrumbs__arrows_disabled {
  pointer-events: none;
  background: url('./img/icon-arrow-gray-next.svg') no-repeat center center;
}

.breadcrumbs .breadcrumbs__item_disabled {
  opacity: 0.2;
  pointer-events: none;
}
