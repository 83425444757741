.section_base_books .section__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1080px;
  margin: 0 auto;
}
.section_base_books .section__content::after {
  content: '';
  flex: auto;
}

.section_base_books .section__footer {
  text-align: center;
  padding: 56px 0 110px;
}
