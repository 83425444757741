.book-detail {
  display: flex;
}

.book-detail .book-detail__left {
  padding-bottom: 53px;
  margin-right: 30px;
}
.book-detail .book-detail__right {
  padding-top: 50px;
  width: 100%;
}
.book-detail .book-detail__file .icon {
  margin-right: 14px;
}
.book-detail .book-detail__file {
  display: flex;
  padding: 5px 0;
  justify-content: flex-start;
  align-items: center;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #25242a;
  margin-left: 78px;
  margin-top: 9px;
  text-decoration: none;
}

.book-detail .book-detail__file span {
  border-bottom: 1px solid transparent;
  transition: all 0.2s;
}
.book-detail .book-detail__file:hover span {
  border-bottom: 1px solid rgba(37, 36, 42, 0.3);
}

.book-detail .book-detail__img {
  box-shadow: 0 10px 40px rgba(112, 112, 102, 0.25);
  border-radius: 6px;
  margin: -32px 42px 34px;
}
.book-detail .book-detail__year {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #36343d;
}
.book-detail .book-detail__content {
  padding-right: 24px;
  height: 502px;
}
.book-detail .book-detail__title {
  font-family: Prata, 'Times New Roman', serif;
  font-style: normal;
  font-weight: normal;
  margin-top: 30px;
  font-size: 24px;
  line-height: 44px;
  color: #25242a;
}
.book-detail .book-detail__type {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #36343d;
}
.book-detail .book-detail__desc {
  margin-top: 32px;
}

.book-detail__arrow_left,
.book-detail__arrow_right {
  position: absolute;
  width: 200px;
  height: 500px;
  display: block;
  top: 50%;
  margin-top: -250px;
  transition: all 0.2s;
}

.book-detail__arrow_disable {
  pointer-events: none;
  opacity: 0.3;
}

.book-detail__arrow_left:hover {
  width: 210px;
  left: -210px;
}
.book-detail__arrow_right:hover {
  width: 210px;
  right: -210px;
}

.book-detail__arrow_left {
  left: -200px;
  background: url('./img/icon-arrow-white-prev.svg') no-repeat center center;
}

.book-detail__arrow_right {
  right: -200px;
  background: url('./img/icon-arrow-white-next.svg') no-repeat center center;
}
