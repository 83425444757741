.strange-white-background {
  position: relative;
}

.strange-white-background .strange-white-background__inner {
  background: white;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 380px;
  z-index: -2;
}
