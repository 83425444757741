// Карта
.map-component {
  height: 500px;
  margin-bottom: 4em;
}
.balloon-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0.5em;
}
.balloon-text {
  font-size: 16px;

  p {
    margin: 0;
  }
  img {
    max-width: 100%;
  }
}

// Список меток
.map-points-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2em;
  margin-bottom: 3em;
}
.map-point-list-item {
  padding: 2em;
  border: 1px solid #d8d8d8;
  text-align: center;

  &-title {
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 1em;
  }

  &-text p {
    margin-bottom: 0.3em;
  }

  img {
    max-width: 100%;
  }
}
