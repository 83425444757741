.carousel_like-news {
  padding: 0 110px;
  outline: none;
}

.carousel_like-news .carousel__item {
  padding: 0 36px;
}

.carousel_like-news .flickity-prev-next-button {
  top: 30%;
}
.carousel_like-news .flickity-prev-next-button.next {
  width: 74px;
  height: 74px;
  background: url('./img/icon-arrow-orange-next.svg') no-repeat center center;
}

.carousel_like-news .flickity-prev-next-button.previous {
  width: 74px;
  height: 74px;
  background: url('./img/icon-arrow-orange-prev.svg') no-repeat center center;
}

.carousel_like-news .flickity-button-icon {
  display: none;
}
