.section_photo-video-gallery {
  background: #36343d;
  border-radius: 10px;
  z-index: 1;
  position: relative;
}

.section_photo-video-gallery .section__wrapper {
  background: url('../img/man.png') no-repeat center center;
  background-size: cover;
}

.section_photo-video-gallery .section__title {
  color: white;
  text-align: center;
  padding-top: 180px;
  padding-bottom: 40px;
  background: url('../img/icon-orange-line.svg') no-repeat center bottom;
}

.section_photo-video-gallery .section__content {
  text-align: center;
}

.section_photo-video-gallery .section__item {
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  padding: 14px 20px;
  display: inline-block;
  position: relative;
}

.section_photo-video-gallery .section__img {
  position: absolute;
  left: 50%;
  margin-left: -157px;
  top: 50%;
  margin-top: -99px;
  width: 314px;
  height: 198px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  z-index: 3;
}

.section_photo-video-gallery .section__item:hover .section__img {
  opacity: 1;
  visibility: visible;
}

.section_photo-video-gallery .section__footer {
  text-align: center;
  padding: 55px 0 170px;
}

.section_photo-video-gallery .button__text {
  color: white;
}
