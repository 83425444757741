.section_base .container {
  overflow: hidden;
}

.section_base .section__title {
  text-align: center;
  padding-top: 108px;
  margin-bottom: 0;
  margin-top: 0;
}
