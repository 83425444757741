.header {
  background: rgba(37, 36, 42, 0.96);
  width: 100%;
}

.header.header_fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.header .header__wrapper {
  min-height: 96px;
  display: flex;
  align-items: stretch;
}

.header .header__logo {
  width: 240px;
  background: #36343d;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  flex-shrink: 0;
}

.header .header__left-stripe {
  background: #36343d;
  width: 100vw;
  height: 96px;
  position: absolute;
  top: 0;
  right: 100%;
}

.header .header__menu {
  flex-shrink: 0;
}

.header .header__search {
  position: relative;
  flex-shrink: 0;
  width: 100px;
}

.header .header__search-inner {
  position: absolute;
  top: 50%;
  margin-top: -24px;
  left: -30px;
}

.header .header__socials {
  justify-self: flex-end;
  width: 100%;
  flex-grow: 1;
}
