.search-result {
  margin-bottom: 40px;
  display: flex;
}

.search-result a {
  text-decoration: none !important;
}

.search-result .search-result__number {
  font-size: 18px;
  line-height: 28px;
  color: #9b999e;
  width: 90px;
  flex-shrink: 0;
  flex-grow: 0;
}

.search-result .search-result__title {
  font-size: 18px;
  line-height: 28px;
  color: #25242a;
}

.search-result .search-result__text {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #25242a;
  margin-top: 12px;
}

.search-result .search-result__link:hover {
  color: #36343d;
}

.search-result .search-result__link {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #9b999e;
  display: inline-block;
  margin-top: 12px;
  text-decoration: none;
}

.search-result span {
  background: #f8d6c7;
  display: inline-block;
  padding: 0 2px;
}
